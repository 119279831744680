import { createWeb3Modal } from '@web3modal/wagmi/react';
import { defaultWagmiConfig } from '@web3modal/wagmi/react/config';
import { WagmiProvider } from 'wagmi';
import { arbitrum, bsc, mainnet, base, polygon, linea, optimism, opBNB } from 'wagmi/chains';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactNode } from 'react';
import { FallbackProvider, JsonRpcProvider } from 'ethers'
import type { Client, Chain, Transport } from 'viem'
import { injected, http, noopStorage, createStorage, getConnectorClient, estimateGas, estimateFeesPerGas } from '@wagmi/core'

// 0. Setup queryClient
const queryClient = new QueryClient();
// 1. Get projectId at https://cloud.walletconnect.com
const projectId = '11471193f63c9a7d8fa54b352048b2f0';
// 2. Create wagmiConfig
const metadata = {
  name: 'Web3Modal',
  description: 'Web3-modal-wallet',
  url: 'https://web3modal.com', // origin must match your domain & subdomain
  icons: ['https://avatars.githubusercontent.com/u/37784886'],
  enableExplorer: false,
  showQrModal: true,
};
const chains = [mainnet, arbitrum, bsc, base, polygon, linea, optimism, opBNB] as const;
const chainIds = chains.map((chain) => chain.id).join(',')
const key = `wagmi-${process.env.NEXT_PUBLIC_VERCEL_ENV}-chains-${chainIds}`
const config = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
  enableInjected: true,
  //connectors: [injected({ shimDisconnect: true })],
  // connectors:[injected()],
  enableCoinbase: false,
  auth: {
    email: false, // default to true
    socials: [],
    showWallets: true,
    walletFeatures: true
  },
  /** 
  storage: createStorage({
    key,
    storage:
      typeof window !== 'undefined' && window.localStorage
        ? window.localStorage
        : noopStorage
  })*/
  //...wagmiOptions // Optional - Override createConfig parameters
});
function clientToProvider(client: Client<Transport, Chain>) {
  const { chain, transport } = client
  const network = {
    chainId: chain.id,
    name: chain.name,
    ensAddress: chain.contracts?.ensRegistry?.address,
  }
  if (transport.type === 'fallback') {
    const providers = (transport.transports as ReturnType<Transport>[]).map(
      ({ value }) => new JsonRpcProvider(value?.url, network),
    )
    if (providers.length === 1) return providers[0]
    return new FallbackProvider(providers)
  }
  return new JsonRpcProvider(transport.url, network)
}
// 3. Create modal
const modal = createWeb3Modal({
  wagmiConfig: config,
  projectId,
  allowUnsupportedChain: false,
  enableAnalytics: false, // Optional - defaults to your Cloud configuration
  enableOnramp: false, // Optional - false as default
  featuredWalletIds: [
    'ecc4036f814562b41a5268adc86270fba1365471402006302e70169465b7ac18',
    'fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa',
    '1ae92b26df02f0abca6304df07debccd18262fdf5fe82daa81593582dac9a369',
    '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0',
    '19177a98252e07ddfc9af2083ba8e07ef627cb6103467ffebb3f8f4205fd7927'
  ],
  excludeWalletIds: [
    'a797aa35c0fadbfc1a53e7f675162ed5226968b44a19ee3d24385c64d1d3c393',
    '163d2cf19babf05eb8962e9748f9ebe613ed52ebf9c8107c9a0f104bfcf161b3',
    'e7c4d26541a7fd84dbdfa9922d3ad21e936e13a7a0e44385d44f006139e44d3b'
  ]
});
function Web3ModalProvider({ children }: { children: ReactNode }) {
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        {children}
      </QueryClientProvider>
    </WagmiProvider>
  );
}
async function getClient() {
  return await getConnectorClient(config)
}



export {
  estimateFeesPerGas,
  estimateGas,
  config,
  modal,
  getClient,
  clientToProvider,
  Web3ModalProvider,
}
