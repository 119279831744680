import * as React from "react";

interface DataOAI {
    aix_amt: string,
    xai_amt: string,
    xai_allowance_amt: string,
    xai_allowane_enabled: boolean,
    address_wallet: string,
    progress: number,
    silder_init_amount: number,
    slider_amount: BigInt
}

interface MainContentProps {
    isMobile: boolean;
    isConnected: boolean;
    toggleAction?: () => void;
    actionApprove?: () => void;
    actionInteract?: () => void;
}

interface ContentClaimProps {
    isloading: boolean;
    isConnected: boolean;
    isClaimFailure: boolean;
    clickAction: () => void;
    actionDisconnect: () => void;
    toastMessage?: () => void;
}

interface SliderSettings {
}
const DappDataContext = React.createContext<DataOAI>({
    aix_amt: "",
    xai_amt: "",
    xai_allowance_amt: "",
    address_wallet: "",
    progress: 0,
    silder_init_amount: 0,
    slider_amount: BigInt(0),
    xai_allowane_enabled: false
});

function useDappDataContext() {
    let context = React.useContext<DataOAI>(DappDataContext)
    // If context is undefined, we know we used RadioGroupItem
    // outside of our provider so we can throw a more helpful
    // error!
    if (context === undefined) {
        throw Error('RadioGroupItem must be used inside of a RadioGroup, ' + 'otherwise it will not function correctly.')
    }

    // Because of TypeScript's type narrowing, if we make it past
    // the error the compiler knows that context is always defined
    // at this point, so we don't need to do any conditional
    // checking on its values when we use this hook!
    return context
}
const DappDataProvider = ({ children }: React.PropsWithChildren<{}>) => {
    const value = useDappDataContext()
    return (
        <DappDataContext.Provider value={value}>
            {children}
        </DappDataContext.Provider>
    )
}


export type {
    DataOAI, MainContentProps, ContentClaimProps, SliderSettings
}
export {
    DappDataContext,
    DappDataProvider,
    useDappDataContext,
}