
import * as React from "react";
import { Button, Space, ConfigProvider, Checkbox, Progress, Flex, Layout, Watermark, Input, Card, Col, Row } from 'antd';
import { createStyles } from 'antd-style';
import { AntDesignOutlined, FileDoneOutlined, SketchOutlined } from '@ant-design/icons';
import type { ProgressProps } from 'antd';
import { NumberSliderInput } from "./slidernum";
import type { MainContentProps, ContentClaimProps } from './types';
import { useDappDataContext, DappDataProvider } from './types';
const { Header, Footer, Sider, Content } = Layout;

const headerStyle: React.CSSProperties = {
    textAlign: 'center',
    color: '#fff',
    height: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingInline: 48,
    lineHeight: '64px',
    backgroundColor: '#aacccc',
};


const contentStyle: React.CSSProperties = {
    textAlign: 'center',
    minHeight: 120,
    lineHeight: '120px',
    color: '#fff',
    paddingInline: 48,
    paddingTop: 24,
    paddingBottom: 24,
    backgroundColor: '#bbf9ff',
};

const siderStyleL: React.CSSProperties = {
    padding: 24,
};
const siderStyleM: React.CSSProperties = {
    padding: 4,
};
const footerStyle: React.CSSProperties = {
    textAlign: 'center',
    color: '#fff',
    backgroundColor: '#4096ff',
};

const layoutStyle = {
    //borderRadius: 8,
    overflow: 'hidden',
    // width: 'calc(50% - 8px)',
    //maxWidth: 'calc(50% - 8px)',
    height: '100%',
    width: '100%',
    justify: 'center'
};
const { Meta } = Card;

const twoColors: ProgressProps['strokeColor'] = {
    '0%': '#108ee9',
    '100%': '#87d068',
};
const conicColors: ProgressProps['strokeColor'] = {
    '0%': '#9c9c9c',
    '50%': '#ffe58f',
    '100%': '#ffccc7',
};
const useStyle = createStyles(({ prefixCls, css }) => ({
    linearGradientButton: css`
      &.${prefixCls}-btn-primary:not([disabled]):not(.${prefixCls}-btn-dangerous) {
        border-width: 0;
  
        > span {
          position: relative;
        }
  
        &::before {
          content: '';
          background: linear-gradient(125deg, #9c9c9c, #04befe);
          position: absolute;
          inset: 0;
          opacity: 1;
          transition: all 0.3s;
          border-radius: inherit;
        }
  
        &:hover::before {
          opacity: 0;
        }
      }
    `,
}));


const AboutPage: React.FC<ContentClaimProps> = ({ isConnected, isClaimFailure, isloading, toastMessage, clickAction, actionDisconnect }) => {
    return (
        <div className="about-page">
            <div className="about-page__content">
                <div className="about-page__content__title">
                    <h1>About</h1>
                </div>
                <div className="about-page__content__description">
                    <p>Here you can find information about the project, its goals, and how it works.</p>
                </div>
            </div>
        </div>
    )
}


const baseStyle: React.CSSProperties = {
    width: '25%',
    height: 54,
    justifyContent: 'start'
};
const baseStyleSpaceig: React.CSSProperties = {
    width: '75%',
    height: 54,
};
const layout_style = {
    //borderRadius: 8,
    overflow: 'hidden',
    width: 'calc(90% - 8px)',
    //maxWidth: 'calc(50% - 8px)',
    //height: '100%',
    // width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    justify: 'center'
};

const MainContentDev: React.FC<MainContentProps> = ({ toggleAction, actionApprove, actionInteract, isConnected, isMobile }) => {
    const { styles } = useStyle();
    const dapp_context = useDappDataContext();
    const [disabled, setDisabled] = React.useState(true);
    const [disabled_slider, setDisabledSlider] = React.useState(false);
    const [connection_smt, setConnectionSmt] = React.useState("");
    const [sub_address, setSubAddress] = React.useState("");

    React.useEffect(() => {
        if (!isConnected) {
            setConnectionSmt("Please connect your wallet");
            setDisabled(true);
        } else {
            setConnectionSmt("You have connected");
            if (dapp_context.xai_allowane_enabled) {
                setDisabled(false);
            } else {
                setDisabled(true);
            }

            if (dapp_context.silder_init_amount === 0) {
                setDisabledSlider(true);
            } else {
                setDisabledSlider(false);
            }
            if (isMobile) {
                setSubAddress(dapp_context.address_wallet.substring(0, 6) + "..." + dapp_context.address_wallet.substring(dapp_context.address_wallet.length - 6, dapp_context.address_wallet.length));
            } else {
                setSubAddress(dapp_context.address_wallet);
            }
        }
    })

    return (
        <ConfigProvider button={{
            className: styles.linearGradientButton,
        }} theme={{ token: { colorPrimary: '#00b96b' } }}>
            <DappDataProvider>
                <Flex gap="middle" wrap>
                    <Watermark style={layoutStyle} content="XAI OFFER">
                        <Header style={headerStyle}>
                            <Flex vertical={false}>
                                <div style={baseStyle}>
                                    <AntDesignOutlined />
                                </div>
                                <div style={baseStyleSpaceig}></div>
                            </Flex>
                            <Flex vertical gap="middle">
                                <div className="progess_zone">
                                    The funding bar will be completed when the softcap 10500000 AIX is completely raised.
                                </div>
                                <Progress percent={dapp_context.progress} status="active" strokeColor={{ from: '#108ee9', to: '#87d068' }} />
                            </Flex>
                        </Header>
                        <Content style={contentStyle}>
                            <Row gutter={8} style={isMobile ? siderStyleM : siderStyleL}>
                                <div className="progess_zone">
                                    {connection_smt}
                                </div>
                                <Input className="addressbar" size="large" disabled={isConnected} placeholder="wallet address detected" prefix={"🔥"} value={sub_address} />
                            </Row>
                            <Row gutter={8}>
                                <Col xs={{ span: 24, offset: 0 }} md={{ span: 12, offset: 0 }} style={{ marginBottom: 16 }}>
                                    <Card title="YOU SEND" bordered={false}>
                                        <div className="borderfx card-button-amount">
                                            {dapp_context.xai_amt}
                                            XAI
                                        </div>
                                        <Flex style={layout_style} vertical>
                                            <NumberSliderInput />
                                            <Space align="start">
                                                {!isConnected && (
                                                    <Button type="primary" size="large" icon={<AntDesignOutlined />} onClick={toggleAction}>
                                                        Connect Wallet
                                                    </Button>
                                                )}
                                                {isConnected && (
                                                    <Button type="primary" disabled={disabled_slider} size="large" icon={<FileDoneOutlined />} onClick={actionApprove}>
                                                        Donate
                                                    </Button>
                                                )}
                                            </Space>
                                        </Flex>
                                    </Card>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} md={{ span: 12, offset: 0 }} style={{ marginBottom: 16 }}>
                                    <Card title="YOU WILL RECEIVE" bordered={false}>
                                        <div className="borderfx card-button-amount">
                                            {dapp_context.xai_allowance_amt}
                                            AIX
                                        </div>
                                        <div className="auth_zone">
                                            You have {dapp_context.aix_amt} AIX in your wallet
                                        </div>

                                        <Space align="start">
                                            {!isConnected && (
                                                <Button type="primary" size="large" icon={<AntDesignOutlined />} onClick={toggleAction}>
                                                    Connect Wallet
                                                </Button>
                                            )}
                                            {isConnected && (
                                                <Button type="primary" disabled={disabled} size="large" icon={<SketchOutlined />} onClick={actionInteract}>
                                                    Confirm Donate
                                                </Button>
                                            )}

                                        </Space>
                                    </Card>
                                </Col>
                            </Row>


                        </Content>
                        <Content style={isMobile ? siderStyleM : siderStyleL}>
                            <p>
                                <Checkbox defaultChecked disabled>Price: 1:1</Checkbox>
                            </p>
                            <p>
                                <Checkbox defaultChecked disabled>Slippage Tolerance: 0%</Checkbox>
                            </p>
                        </Content>
                        <Footer style={footerStyle}>Trade AIX | Mining AIX | Whitepaper | Terms & Conditions | <a href="/about">About</a></Footer>

                    </Watermark>
                </Flex>
            </DappDataProvider>
        </ConfigProvider >
    );
};

export { MainContentDev, AboutPage }