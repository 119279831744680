import * as React from "react";
import { Slider, Space, InputNumber, Checkbox, Progress, Flex, Layout, Watermark, Input, Card, Col, Row } from 'antd';
import { createStyles } from 'antd-style';

import type { InputNumberProps } from 'antd';
import type { DataOAI, SliderSettings } from './types';
import { useDappDataContext } from './types';
import { background } from "@chakra-ui/react";
const inputstyle = {
    //borderRadius: 8,
    overflow: 'hidden',
    //width: 'calc(90% - 8px)',
    //maxWidth: 'calc(50% - 8px)',
    //height: '100%',
    width: '100%',
    //marginLeft: 'auto',
    //marginRight: 'auto',
    justify: 'center'
};
const inputbar = {
    width: 'calc(90% - 8px)',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 30,
    justify: 'center'
}
const layoutStyle = {
    //borderRadius: 8,
    overflow: 'hidden',
    // width: 'calc(50% - 8px)',
    //maxWidth: 'calc(50% - 8px)',
    //height: '100%',
    backgroundColor: 'transparent',
    color: 'black',
    width: '100%',
    //justify: 'center'
};

const NumberSliderInput: React.FC = () => {
    const dappdata = useDappDataContext();

    // amt is the amount of coins the user has
    const [disabled, setDisabled] = React.useState(false);
    const [coinVal, setCoinVal] = React.useState(0);

    React.useEffect(() => {
        if (dappdata.silder_init_amount === 0) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    })
    const finalize = (end_value: number) => {
        const endpt = end_value * 1000000000000000000;
        //const end_val = BigInt(end_value.toString()) * BigInt("1000000000000000000");
        const end_val = BigInt(endpt);
        //dappdata.setSliderAmt(end_val);
        dappdata.slider_amount = end_val;
        console.log("slider amount: ", dappdata.slider_amount);
    }

    const onNumChange: InputNumberProps['onChange'] = (value) => {
        setCoinVal(value as number);
        finalize(value as number);
    };

    const onChangeSlider: InputNumberProps['onChange'] = (value) => {
        const end_value = dappdata.silder_init_amount * (value as number) / 100
        setCoinVal(end_value as number);

    };
    const onChangeEnd: InputNumberProps['onChange'] = (value) => {
        const end_value = dappdata.silder_init_amount * (value as number) / 100
        finalize(end_value);
    }

    return (
        <Flex gap="middle" wrap>
            <Layout style={layoutStyle} >
                {
                    (disabled) && (
                        <div className="progess_zone">
                            Get XAI from Binance
                        </div>
                    )
                }
                {
                    (!disabled) && (
                        <div className="progess_zone">
                            Authorize Amount
                        </div>
                    )
                }
                <InputNumber value={coinVal} disabled={disabled} style={inputstyle} prefix="XAI" size="large" min={1} max={dappdata.silder_init_amount} defaultValue={0} onChange={onNumChange} />
                <Slider style={inputbar} onChangeComplete={onChangeEnd} onChange={onChangeSlider} defaultValue={0} disabled={disabled} />
            </Layout>
        </Flex>
    );
};
export { NumberSliderInput };
export type { SliderSettings }